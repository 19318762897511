@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.form-signin {
    max-width: 350px;
    padding: 1rem;
}

.invite {
    max-width: 350px;
}

.cursor-pointer {
    cursor: pointer;
}

html[data-bs-theme="dark"] img.dark-invert {
    filter: invert(100%);
}

.card.board > .card-body {
    backdrop-filter: brightness(0.85);
}

html[data-bs-theme="dark"] .card.board > .card-body {
    backdrop-filter: brightness(0.6);
}


.vertically-centered {
    height: 6rem;
    overflow: hidden;
    font-weight: bold;
    font-size: 1.75rem;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
    line-height: 1.1;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.vertically-centered:after {
    content: "";
    height: 100%;
    display: inline-block;
    vertical-align: middle;
}

.vertically-centered div {
    display: inline-block;
    /*display: -webkit-inline-box;*/
    max-height: 100%;
    width: 100%;
    vertical-align: middle;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.dropdown-menu.emojis {
    &.show {
        display: flex;
        width: 300px;
        flex-wrap: wrap;
        max-height: 300px;
        overflow-y: scroll;
    }

    .dropdown-header {
        width: 100%;
    }

    .dropdown-item {
        width: 10%;
    }
}

.btn-outline-form {
    border-color: $input-border-color;
}

.hide-after:after {
    display: none;
}